import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
} from '@apollo/client/core'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { Auth } from 'aws-amplify'

const url = process.env.VUE_APP_AWS_APPSYNC_ENDPOINT as string
const region = process.env.VUE_APP_AWS_APPSYNC_REGION as string
const auth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken(),
}

createHttpLink({ uri: url })

const link = ApolloLink.from([
  removeTypenameFromVariables(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }),
])

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    resultCaching: false,
    addTypename: true,
    typePolicies: {
      Tax: {
        keyFields: ['taxId'],
      },
      // ShippingProfile: {
      //   keyFields: false,
      // },
    },
  }),
})

export default apolloClient
