import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { getCurrentUser } from '@/lib/user'
const Home = () => import(/* webpackChunkName: "home" */ '../pages/index.vue')
const Layout = () =>
  import(/* webpackChunkName: "layout" */ '../layouts/index.vue')
const Discounts = () =>
  import(/* webpackChunkName: "discounts" */ '../pages/discounts/Discounts.vue')
const DiscountDetails = () =>
  import(
    /* webpackChunkName: "discount-details" */ '../pages/discounts/DiscountDetails.vue'
  )
const Orders = () =>
  import(/* webpackChunkName: "orders" */ '../pages/orders/Orders.vue')
const Customers = () =>
  import(/* webpackChunkName: "customers" */ '../pages/customers/Customers.vue')
const Products = () =>
  import(/* webpackChunkName: "products" */ '../pages/products/Products.vue')
const Product = () =>
  import(/* webpackChunkName: "product" */ '../pages/products/Product.vue')
const Variant = () =>
  import(/* webpackChunkName: "variant" */ '../pages/products/Variant.vue')
const OrderRefund = () =>
  import(
    /* webpackChunkName: "order-refund" */ '../pages/orders/OrderRefund.vue'
  )
const OrderFulfillment = () =>
  import(
    /* webpackChunkName: "order-fullfillment" */ '../pages/orders/OrderFulfillment.vue'
  )
const CustomerDetails = () =>
  import(
    /* webpackChunkName: "customer-details" */ '../pages/customers/CustomerDetails.vue'
  )
const Taxes = () =>
  import(/* webpackChunkName: "taxes" */ '@/pages/settings/taxes/Taxes.vue')

const Markets = () =>
  import(
    /* webpackChunkName: "markets" */ '@/pages/settings/markets/Markets.vue'
  )
const Market = () =>
  import(
    /* webpackChunkName: "markets" */ '@/pages/settings/markets/Market.vue'
  )
const NewMarket = () =>
  import(
    /* webpackChunkName: "markets" */ '@/pages/settings/markets/NewMarket.vue'
  )
const MarketVariants = () =>
  import(
    /* webpackChunkName: "markets" */ '@/pages/settings/markets/MarketVariants.vue'
  )

const Settings = () =>
  import(/* webpackChunkName: "settings" */ '@/pages/settings/Settings.vue')
const OrderDetails = () =>
  import(
    /* webpackChunkName: "order-details" */ '../pages/orders/OrderDetails.vue'
  )
const CreateCustomer = () =>
  import(
    /* webpackChunkName: "create-customer" */ '../pages/customers/CreateCustomer.vue'
  )
const Tax = () =>
  import(/* webpackChunkName: "tax" */ '@/pages/settings/taxes/Tax.vue')
const Login = () =>
  import(/* webpackChunkName: "login" */ '../pages/auth/login.vue')

const FulfillmentGroups = () =>
  import(
    /* webpackChunkName: "fulfillment-groups" */ '@/pages/fulfillments/FulfillmentGroups.vue'
  )
const EditFulfillment = () =>
  import(
    /* webpackChunkName: "fulfillment-groups-edit" */ '@/pages/fulfillments/EditFulfillment.vue'
  )
const FulfillmentGroupForm = () =>
  import(
    /* webpackChunkName: "fulfillment-group-form" */ '@/pages/fulfillments/FulfillmentGroupForm.vue'
  )
const Fulfillment = () =>
  import(
    /* webpackChunkName: "fulfillment" */ '@/pages/fulfillments/Fulfillment.vue'
  )
const FulfillmentImport = () =>
  import(
    /* webpackChunkName: "fulfillmentImport" */ '@/pages/fulfillments/FulfillmentImport.vue'
  )
const SalesReport = () =>
  import(/* webpackChunkName: "SalesReport" */ '@/pages/reports/Sales.vue')

const ProductReport = () =>
  import(/* webpackChunkName: "ProductReport" */ '@/pages/reports/Product.vue')

const ProductPricesReport = () =>
  import(
    /* webpackChunkName: "ProductPricesReport" */ '@/pages/reports/ProductPrices.vue'
  )

const DiscountReport = () =>
  import(
    /* webpackChunkName: "DiscountReport" */ '@/pages/reports/Discount.vue'
  )
const RefundsReport = () =>
  import(/* webpackChunkName: "RefundsReport" */ '@/pages/reports/Refunds.vue')
const LocationReport = () =>
  import(
    /* webpackChunkName: "LocationReport" */ '@/pages/reports/Location.vue'
  )
const ReportSettings = () =>
  import(
    /* webpackChunkName: "ReportSettings" */ '@/pages/reports/Settings.vue'
  )

const AnalyticsDashboard = () =>
  import(
    /* webpackChunkName: "AnalyticsDashboard" */ '@/pages/reports/Dashboard.vue'
  )

const Shipping = () =>
  import(
    /* webpackChunkName: "shipping" */ '@/pages/settings/shipping/Shipping.vue'
  )

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Layout,
    beforeEnter: async (_, __, next) => {
      try {
        await getCurrentUser()
        next()
      } catch (err) {
        console.error('[ROUTE] Not authenticated')
        next('/login')
      }
    },
    children: [
      {
        name: 'Home',
        path: '',
        component: Home,
      },
      {
        name: 'Discounts',
        path: 'discounts',
        component: Discounts,
      },
      {
        name: 'EditDiscount',
        path: 'discounts/:discountId',
        component: DiscountDetails,
        props: {
          pageType: 'EDIT',
        },
      },
      {
        name: 'AddDiscountCode',
        path: 'discounts/code/new',
        component: DiscountDetails,
        props: {
          pageType: 'ADD',
        },
      },
      {
        name: 'AddDiscountAutomatic',
        path: 'discounts/automatic/new',
        component: DiscountDetails,
        props: {
          pageType: 'ADD',
        },
      },
      {
        name: 'Orders',
        path: 'orders',
        component: Orders,
      },
      {
        name: 'OrderDetails',
        path: 'orders/:id',
        component: OrderDetails,
      },
      {
        name: 'OrderRefund',
        path: 'orders/:id/refund',
        component: OrderRefund,
      },
      {
        name: 'OrderFulfillment',
        path: 'orders/:id/fulfillment/:variant',
        component: OrderFulfillment,
      },
      {
        name: 'Products',
        path: 'products',
        component: Products,
      },
      {
        name: 'AddProduct',
        path: 'products/new',
        component: Product,
      },
      {
        name: 'ProductDetails',
        path: 'products/:id',
        component: Product,
      },
      {
        name: 'AddVariant',
        path: 'products/:productId/variant',
        component: Variant,
        props: {
          pageType: 'ADD',
        },
      },
      {
        name: 'EditVariant',
        path: 'products/:productId/variant/:variantId',
        component: Variant,
        props: {
          pageType: 'EDIT',
        },
      },
      {
        name: 'DuplicateVariant',
        path: 'products/:productId/variant/:variantId/duplicate',
        component: Variant,
        props: {
          pageType: 'DUPLICATE',
        },
      },
      {
        name: 'Customers',
        path: 'customers',
        component: Customers,
      },
      {
        name: 'CreateCustomer',
        path: 'customers/create',
        component: CreateCustomer,
      },
      {
        name: 'CustomerDetails',
        path: 'customers/:id',
        component: CustomerDetails,
      },
      {
        name: 'Settings',
        path: 'settings',
        component: Settings,
      },
      {
        name: 'Taxes',
        path: 'settings/taxes',
        component: Taxes,
      },
      {
        name: 'Tax',
        path: 'settings/taxes/:taxId',
        component: Tax,
      },
      {
        name: 'CreateTax',
        path: 'settings/taxes/new',
        component: Tax,
      },
      {
        name: 'Markets',
        path: 'settings/markets',
        component: Markets,
      },
      {
        name: 'Market',
        path: 'settings/markets/new',
        component: NewMarket,
      },
      {
        name: 'Market',
        path: 'settings/markets/:id',
        component: Market,
      },
      {
        name: 'MarketVariants',
        path: 'settings/markets/:marketId/:productId',
        component: MarketVariants,
      },
      {
        name: 'Shipping',
        path: 'settings/shipping',
        component: Shipping,
      },
      {
        name: 'FulfillmentGroups',
        path: 'fulfillment/groups',
        component: FulfillmentGroups,
      },
      {
        name: 'CreateFulfillmentGroup',
        path: 'fulfillment/groups/new',
        component: FulfillmentGroupForm,
      },
      {
        name: 'Fulfillment',
        path: 'fulfillment/groups/:fulfillmentGroupId',
        component: Fulfillment,
      },
      {
        name: 'EditFulfillment',
        path: 'fulfillment/groups/:fulfillmentGroupId/edit',
        component: EditFulfillment,
      },
      {
        name: 'FulfillmentImport',
        path: 'fulfillment/groups/:fulfillmentGroupId/import/:importId',
        component: FulfillmentImport,
      },
      {
        name: 'SalesReport',
        path: 'reports/sales',
        component: SalesReport,
      },
      {
        name: 'LocationReport',
        path: 'reports/location',
        component: LocationReport,
      },
      {
        name: 'DiscountReport',
        path: 'reports/discounts',
        component: DiscountReport,
      },
      {
        name: 'RefundsReport',
        path: 'reports/refunds',
        component: RefundsReport,
      },
      {
        name: 'ProductReport',
        path: 'reports/product',
        component: ProductReport,
      },
      {
        name: 'ProductPricesReport',
        path: 'reports/product-prices',
        component: ProductPricesReport,
      },
      {
        name: 'ReportsSettings',
        path: 'reports/settings',
        component: ReportSettings,
      },
      {
        name: 'AnalyticsDashboard',
        path: 'reports/dashboard',
        component: AnalyticsDashboard,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
