import gql from 'graphql-tag'

/**
 * Query to get a product for the specified customerId
 */
export const GET_CUSTOMER_BY_ID = gql`
  query getCustomerById($customerId: ID!) {
    getCustomerById(customerId: $customerId) {
      customerId
      firstName
      lastName
      email
      note
      createdAt
      updatedAt
      analytics {
        ordersCount
        averageOrderValue {
          amount
          currency
        }
        totalSpent {
          amount
          currency
        }
      }
      lastOrder {
        orderId
        orderConfirmedAt
      }
      shippingAddress {
        address1
        address2
        addressCode
        city
        company
        country
        countryCode
        division
        divisionCode
        firstName
        lastName
        latitude
        longitude
        phone
      }
      billingAddress {
        address1
        address2
        addressCode
        city
        company
        country
        countryCode
        division
        divisionCode
        firstName
        lastName
        latitude
        longitude
        phone
      }
    }
  }
`

export const GET_SHORT_CUSTOMER_BY_ID = gql`
  query getCustomerById($customerId: ID!) {
    getCustomerById(customerId: $customerId) {
      customerId
      firstName
      lastName
      email
    }
  }
`
