import {
  GET_CUSTOMER_BY_ID,
  GET_SHORT_CUSTOMER_BY_ID,
} from '@/graphql/query/customer'
import apolloClient from '@/services/apollo'
import { Auth } from 'aws-amplify'
import {
  Customer,
  Maybe,
  QueryGetCustomerByIdArgs,
  MutationUpdateCustomerInfoArgs,
  MutationValidateEmailArgs,
  CustomerInput,
  ValidationResult,
} from '../../../shared/types/types'
import { UPDATE_CUSTOMER, VALIDATE_EMAIL } from '@/graphql/mutation/customer'

interface GetCustomerResult {
  getCustomerById: Maybe<Customer>
}

interface UpdateCustomerResult {
  updateCustomerInfo: Maybe<CustomerInput>
}

interface CheckEmailIfValid {
  validateEmail: Maybe<ValidationResult>
}

export const getCurrentUser = (): Promise<any> =>
  Auth.currentAuthenticatedUser()

export const getUserInfo = async (): Promise<any> => Auth.currentUserInfo()

export const signOut = (): Promise<any> => Auth.signOut()

export const getCustomerById = async (
  customerId: string
): Promise<Maybe<Customer>> => {
  const { data } = await apolloClient.query<
    GetCustomerResult,
    QueryGetCustomerByIdArgs
  >({
    query: GET_CUSTOMER_BY_ID,
    variables: {
      customerId,
    },
    fetchPolicy: 'network-only',
  })

  return data.getCustomerById
}

export const getShortCustomerById = async (
  customerId: string
): Promise<Maybe<Customer>> => {
  const { data } = await apolloClient.query<
    GetCustomerResult,
    QueryGetCustomerByIdArgs
  >({
    query: GET_SHORT_CUSTOMER_BY_ID,
    variables: {
      customerId,
    },
    fetchPolicy: 'network-only',
  })

  return data.getCustomerById
}

/* Update Customer
 *
 * @param firstName First Name
 * @param lastName Last Name
 * @param email Email
 */
export const updateCustomerInfo = async (
  customer: CustomerInput,
  customerId: string
): Promise<any> => {
  const { data } = await apolloClient.mutate<
    UpdateCustomerResult,
    MutationUpdateCustomerInfoArgs
  >({
    mutation: UPDATE_CUSTOMER,
    variables: {
      customer,
      customerId,
    },
  })

  return data?.updateCustomerInfo
}

export const validateEmail = async (email: string): Promise<any> => {
  const { data } = await apolloClient.mutate<
    CheckEmailIfValid,
    MutationValidateEmailArgs
  >({
    mutation: VALIDATE_EMAIL,
    variables: {
      email,
    },
  })

  return data?.validateEmail
}
